/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listApplications = /* GraphQL */ `
  query ListApplications(
    $id: ID
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        teacherID
        questions {
          lastYear {
            attended
            chair
            group
            part
          }
          experience {
            instrument {
              doubles
              years
            }
            jazzEnsemble
            orchestral
            vocalJazzAccompaniment
          }
          moreThanOne {
            response
            instruments
          }
          organizations {
            school
            other
          }
          previousSoloRating {
            ninth {
              level
              grade
            }
            tenth {
              level
              grade
            }
          }
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
        }
        recommendation
        comments
        ranking {
          score
          local
          state
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
      }
      nextToken
    }
  }
`;

export const listBuses = /* GraphQL */ `
    query ListBuses(
        $id: ID
        $filter: ModelBusFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listBuses(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                capacity
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listDistricts = /* GraphQL */ `
  query ListDistricts(
    $id: ID
    $filter: ModelDistrictFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistricts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        beds
        slug
        name {
          legal
          popular
        }
        avatar {
          size
          name
          src
        }
        address {
          line1
          line2
          city
          county
          state
          zip
        }
        staff {
          type
          title
        }
        state {
          enabled
        }
        createdAt
        updatedAt
        districtZoneId
      }
      nextToken
    }
  }
`;
export const listDocumentCategories = /* GraphQL */ `
  query ListDocumentCategories(
    $id: ID
    $filter: ModelDocumentCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        title
        description
        icon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $id: ID
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        categoryID
        title
        description
        content
        isPublished
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEnsembles = /* GraphQL */ `
  query ListEnsembles(
    $id: ID
    $filter: ModelEnsembleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnsembles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        code
        parts
        hasParts
        hasAlternates
        state {
          enabled
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFestivals = /* GraphQL */ `
  query ListFestivals(
    $id: ID
    $filter: ModelFestivalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFestivals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        site
        address {
          line1
          line2
          city
          county
          state
          zip
        }
        state {
          enabled
        }
        createdAt
        updatedAt
        festivalZoneId
      }
      nextToken
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $id: ID
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listForms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        type
        state {
          enabled
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInstruments = /* GraphQL */ `
  query ListInstruments(
    $id: ID
    $filter: ModelInstrumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstruments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        order
        state {
          enabled
        }
        createdAt
        updatedAt
        formInstrumentsId
      }
      nextToken
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $id: ID
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvoices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        number
        amount
        dueAt
        memo
        duplicates
        createdAt
        updatedAt
        invoiceStudentId
      }
      nextToken
    }
  }
`;
/*export const listPurchaseOrders = /!* GraphQL *!/ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        amount
        check
        received
        returned
        id
        createdAt
        updatedAt
        schoolPurchaseOrdersId
      }
      nextToken
    }
  }
`;*/
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        amount
        check
        received
        returned
        id
        createdAt
        updatedAt
        invoicePaymentsId
#        purchaseOrderPaymentsId
      }
      nextToken
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $id: ID
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchools(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        beds
        slug
        name {
          legal
          popular
        }
        avatar {
          size
          name
          src
        }
        address {
          line1
          line2
          city
          county
          state
          zip
        }
        staff {
          type
          title
        }
        state {
          enabled
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
      }
      nextToken
    }
  }
`;
export const listSelections = /* GraphQL */ `
  query ListSelections(
    $id: ID
    $filter: ModelSelectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        part
        ranking {
          state
        }
        accepted
        state {
          order
        }
        createdAt
        updatedAt
        selectionApplicationId
        selectionEnsembleId
      }
      nextToken
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $key: String
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $id: ID
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name {
          first
          last
          preferred
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
        }
        phone {
          type
          number
          ext
        }
        email {
          type
          address
        }
        grade
        schoolID
        createdAt
        updatedAt
        studentInvoiceId
        busStudentsId
      }
      nextToken
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $id: ID
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTickets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        text
        status
        labels
        userID
        state {
          locked
          hidden
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTicketReplies = /* GraphQL */ `
  query ListTicketReplies(
    $id: ID
    $filter: ModelTicketReplyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTicketReplies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        text
        state {
          locked
          hidden
        }
        isAutomatedReply
        createdAt
        updatedAt
        ticketRepliesId
        ticketReplyUserId
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        name {
          first
          last
          preferred
        }
        address {
          line1
          line2
          city
          county
          state
          zip
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
        }
        createdAt
        updatedAt
        districtUsersId
      }
      nextToken
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $id: ID
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZones(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        counties
        state {
          enabled
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEnsembleInstruments = /* GraphQL */ `
  query ListEnsembleInstruments(
    $filter: ModelEnsembleInstrumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnsembleInstruments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ensembleID
        instrumentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserSchools = /* GraphQL */ `
  query ListUserSchools(
    $filter: ModelUserSchoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
