<template>
  <div>
    <b-button block variant="primary" :disabled="all.loading" class="mb-2" @click="exportAll()">Export All</b-button>
    <b-card-group columns>
      <b-card title="Applications">
        <b-button block variant="primary" :disabled="applications.loading" @click="exportApplications()">Export</b-button>
      </b-card>
      <b-card title="Cognito Users">
        <b-button block variant="" :disabled="cognitoUsers.loading" @click="exportCognitoUsers()">Export</b-button>
      </b-card>
      <b-card title="Cognito Group Users">
        <b-button block variant="" :disabled="cognitoGroupUsers.loading" @click="exportCognitoGroupUsers()">Export</b-button>
      </b-card>
      <b-card title="Districts">
        <b-button block variant="primary" :disabled="districts.loading" @click="exportDistricts()">Export</b-button>
      </b-card>
      <b-card title="Documents">
        <b-button block variant="warning" :disabled="documents.loading" @click="exportDocuments()">Export</b-button>
      </b-card>
      <b-card title="Document Categories">
        <b-button block variant="warning" :disabled="documentCategories.loading" @click="exportDocumentCategories()">Export</b-button>
      </b-card>
      <b-card title="Ensembles">
        <b-button block variant="primary" :disabled="ensembles.loading" @click="exportEnsembles()">Export</b-button>
      </b-card>
      <b-card title="Ensemble Instruments">
        <b-button block variant="primary" :disabled="ensembleInstruments.loading" @click="exportEnsembleInstruments()">Export</b-button>
      </b-card>
      <b-card title="Festivals">
        <b-button block variant="primary" :disabled="festivals.loading" @click="exportFestivals()">Export</b-button>
      </b-card>
      <b-card title="Forms">
        <b-button block variant="primary" :disabled="forms.loading" @click="exportForms()">Export</b-button>
      </b-card>
      <b-card title="Instruments">
        <b-button block variant="primary" :disabled="instruments.loading" @click="exportInstruments()">Export</b-button>
      </b-card>
      <b-card title="Invoices">
        <b-button block variant="success" :disabled="invoices.loading" @click="exportInvoices()">Export</b-button>
      </b-card>
      <b-card title="Payments">
        <b-button block variant="success" :disabled="payments.loading" @click="exportPayments()">Export</b-button>
      </b-card>
<!--      <b-card title="Purchase Orders">
        <b-button block variant="success" :disabled="payments.loading" @click="exportPurchaseOrders()">Export</b-button>
      </b-card>-->
      <b-card title="Settings">
        <b-button block variant="danger" :disabled="settings.loading" @click="exportSettings()">Export</b-button>
      </b-card>
      <b-card title="Schools">
        <b-button block variant="primary" :disabled="schools.loading" @click="exportSchools()">Export</b-button>
      </b-card>
      <b-card title="Selections">
        <b-button block variant="primary" :disabled="selections.loading" @click="exportSelections()">Export</b-button>
      </b-card>
      <b-card title="Students">
        <b-button block variant="primary" :disabled="students.loading" @click="exportStudents()">Export</b-button>
      </b-card>
      <b-card title="Tickets">
        <b-button block variant="info" :disabled="tickets.loading" @click="exportTickets()">Export</b-button>
      </b-card>
      <b-card title="Ticket Replies">
        <b-button block variant="info" :disabled="ticketReplies.loading" @click="exportTicketReplies()">Export</b-button>
      </b-card>
      <b-card title="Users">
        <b-button block variant="primary" :disabled="users.loading" @click="exportUsers()">Export</b-button>
      </b-card>
      <b-card title="User Schools">
        <b-button block variant="primary" :disabled="userSchools.loading" @click="exportUserSchools()">Export</b-button>
      </b-card>
      <b-card title="Zones">
        <b-button block variant="primary" :disabled="zones.loading" @click="exportZones()">Export</b-button>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {
  listApplications, listBuses, listDistricts,
  listDocumentCategories, listDocuments,
  listEnsembleInstruments, listEnsembles,
  listFestivals, listForms, listInstruments,
  listInvoices, listPayments, /*listPurchaseOrders,*/
  listSchools, listSelections, listSettings, listStudents,
  listTicketReplies, listTickets,
  listUsers, listUserSchools,
  listZones
} from './queries/export';
import {
  adminListGroups,
  adminListUsers,
  adminListUsersInGroup
} from '@/scripts/aws';

export default {
  name: 'Export',
  data() {
   return {
     all: { loading: false },
     applications: { loading: false },
     buses: { loading: false },
     cognitoUsers: { loading: false },
     cognitoGroupUsers: { loading: false },
     districts: { loading: false },
     documents: { loading: false },
     documentCategories: { loading: false },
     ensembles: { loading: false },
     ensembleInstruments: { loading: false },
     festivals: { loading: false },
     forms: { loading: false },
     instruments: { loading: false },
     invoices: { loading: false },
     payments: { loading: false },
     /*purchaseOrders: { loading: false },*/
     settings: { loading: false },
     schools: { loading: false },
     selections: { loading: false },
     students: { loading: false },
     tickets: { loading: false },
     ticketReplies: { loading: false },
     users: { loading: false },
     userSchools: { loading: false },
     zones: { loading: false }
   }
  },
  methods: {
    export(fileName, data) {
      /** Build File && Download **/
      const blob = new Blob([JSON.stringify(data)], { type: 'data:text/plain;charset=utf-8;' });
      const downloadable = document.createElement('a');
      downloadable.href = URL.createObjectURL( blob );
      downloadable.download = `nyssma-${fileName}-${new Date().toISOString()}.json`;
      downloadable.click();
      setTimeout(() => URL.revokeObjectURL( downloadable.href ), 60000 );
    },

    async exportAll() {
      Object.keys(this.$data).forEach(key => { this[key].loading = true })
      await this.exportApplications()
      await this.exportCognitoUsers()
      await this.exportCognitoGroupUsers()
      await this.exportBuses()
      await this.exportDistricts()
      await this.exportDocuments()
      await this.exportDocumentCategories()
      await this.exportEnsembles()
      await this.exportEnsembleInstruments()
      await this.exportFestivals()
      await this.exportForms()
      await this.exportInstruments()
      await this.exportInvoices()
      await this.exportPayments()
      // await this.exportPurchaseOrders()
      await this.exportSettings()
      await this.exportSchools()
      await this.exportSelections()
      await this.exportStudents()
      await this.exportTickets()
      await this.exportTicketReplies()
      await this.exportUsers()
      await this.exportUserSchools()
      await this.exportZones()
      Object.keys(this.$data).forEach(key => { this[key].loading = false })
    },

    /** Amplify **/
    async exportApplications(nextToken, pagedItems) {
      this.applications.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listApplications, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listApplications.items);

      if(response.data.listApplications.nextToken) {
        await this.exportApplications(response.data.listApplications.nextToken, items)
      }
      else {
        this.export('applications', items);
        this.applications.loading = false
      }
    },
    async exportBuses(nextToken, pagedItems) {
      this.buses.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listBuses, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listBuses.items);

      if(response.data.listBuses.nextToken) {
        await this.exportBuses(response.data.listBuses.nextToken, items)
      }
      else {
        this.export('buses', items);
        this.buses.loading = false
      }
    },
    async exportDistricts(nextToken, pagedItems) {
      this.districts.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDistricts, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listDistricts.items);

      if(response.data.listDistricts.nextToken) {
        await this.exportDistricts(response.data.listDistricts.nextToken, items)
      }
      else {
        this.export('districts', items);
        this.districts.loading = false
      }
    },
    async exportDocuments(nextToken, pagedItems) {
      this.documents.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocuments, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listDocuments.items);

      if(response.data.listDocuments.nextToken) {
        await this.exportDocuments(response.data.listDocuments.nextToken, items)
      }
      else {
        this.export('documents', items);
        this.documents.loading = false
      }
    },
    async exportDocumentCategories(nextToken, pagedItems) {
      this.documentCategories.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentCategories, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listDocumentCategories.items);

      if(response.data.listDocumentCategories.nextToken) {
        await this.exportDocumentCategories(response.data.listDocumentCategories.nextToken, items)
      }
      else {
        this.export('document-categories', items);
        this.documentCategories.loading = false
      }
    },
    async exportEnsembles() {
      this.ensembles.loading = true
      try {
        const response = await API.graphql(graphqlOperation(listEnsembles));
        this.export('ensembles', response.data.listEnsembles.items);
      }
      catch(e) { console.error(e) }
      this.ensembles.loading = false
    },
    async exportEnsembleInstruments() {
      this.ensembleInstruments.loading = true
      try {
        const response = await API.graphql(graphqlOperation(listEnsembleInstruments));
        this.export('ensemble-instruments', response.data.listEnsembleInstruments.items);
      }
      catch(e) { console.error(e) }
      this.ensembles.loading = false
    },
    async exportFestivals() {
      this.festivals.loading = true
      const response = await API.graphql(graphqlOperation(listFestivals));
      this.export('festivals', response.data.listFestivals.items);
      this.festivals.loading = false
    },
    async exportForms() {
      this.forms.loading = true
      const response = await API.graphql(graphqlOperation(listForms));
      this.export('forms', response.data.listForms.items);
      this.forms.loading = false
    },
    async exportInstruments() {
      this.instruments.loading = true
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.export('instruments', response.data.listInstruments.items);
      this.instruments.loading = false
    },
    async exportInvoices(nextToken, pagedItems) {
      this.invoices.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listInvoices, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listInvoices.items);

      if(response.data.listInvoices.nextToken) {
        await this.exportInvoices(response.data.listInvoices.nextToken, items)
      }
      else {
        this.export('invoices', items);
        this.invoices.loading = false
      }
    },
    async exportPayments(nextToken, pagedItems) {
      this.payments.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listPayments, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listPayments.items);

      if(response.data.listPayments.nextToken) {
        await this.exportPayments(response.data.listPayments.nextToken, items)
      }
      else {
        this.export('payments', items);
        this.payments.loading = false
      }
    },
    /*async exportPurchaseOrders(nextToken, pagedItems) {
      this.purchaseOrders.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listPurchaseOrders, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listPurchaseOrders.items);

      if(response.data.listPurchaseOrders.nextToken) {
        await this.exportPurchaseOrders(response.data.listPurchaseOrders.nextToken, items)
      }
      else {
        this.export('purchaseOrders', items);
        this.purchaseOrders.loading = false
      }
    },*/
    async exportSettings(nextToken, pagedItems) {
      this.settings.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listSettings, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listSettings.items);

      if(response.data.listSettings.nextToken) {
        await this.exportSettings(response.data.listSettings.nextToken, items)
      }
      else {
        this.export('settings', items);
        this.settings.loading = false
      }
    },
    async exportSchools(nextToken, pagedItems) {
      this.schools.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listSchools.items);

      if(response.data.listSchools.nextToken) {
        await this.exportSchools(response.data.listSchools.nextToken, items)
      }
      else {
        this.export('schools', items);
        this.schools.loading = false
      }
    },
    async exportSelections(nextToken, pagedItems) {
      this.selections.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listSelections, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.exportSelections(response.data.listSelections.nextToken, items)
      }
      else {
        this.export('selections', items);
        this.selections.loading = false
      }
    },
    async exportStudents(nextToken, pagedItems) {
      this.students.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listStudents, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listStudents.items);

      if(response.data.listStudents.nextToken) {
        await this.exportStudents(response.data.listStudents.nextToken, items)
      }
      else {
        this.export('students', items);
        this.students.loading = false
      }
    },
    async exportTickets(nextToken, pagedItems) {
      this.tickets.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listTickets, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listTickets.items);

      if(response.data.listTickets.nextToken) {
        await this.exportTickets(response.data.listTickets.nextToken, items)
      }
      else {
        this.export('tickets', items);
        this.tickets.loading = false
      }
    },
    async exportTicketReplies(nextToken, pagedItems) {
      this.ticketReplies.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listTicketReplies, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listTicketReplies.items);

      if(response.data.listTicketReplies.nextToken) {
        await this.exportTicketReplies(response.data.listTicketReplies.nextToken, items)
      }
      else {
        this.export('ticket-replies', items);
        this.ticketReplies.loading = false
      }
    },
    async exportUsers(nextToken, pagedItems) {
      this.users.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listUsers, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listUsers.items);

      if(response.data.listUsers.nextToken) {
        await this.exportUsers(response.data.listUsers.nextToken, items)
      }
      else {
        this.export('users', items);
        this.users.loading = false
      }
    },
    async exportUserSchools(nextToken, pagedItems) {
      this.userSchools.loading = true
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listUserSchools, { limit: 1000, nextToken: nextToken }));
      items.push(...response.data.listUserSchools.items);

      if(response.data.listUserSchools.nextToken) {
        await this.exportUserSchools(response.data.listUserSchools.nextToken, items)
      }
      else {
        this.export('user-schools', items);
        this.userSchools.loading = false
      }
    },
    async exportZones() {
      this.zones.loading = true
      const response = await API.graphql(graphqlOperation(listZones));
      this.export('zones', response.data.listZones.items);
      this.zones.loading = false
    },

    /** Cognito **/
    async exportCognitoUsers() {
      this.cognitoUsers.loading = true
      try {
        const items = await adminListUsers()
        this.export('cognito-users', items);
      }
      catch(e) { console.error(e) }
      this.cognitoUsers.loading = false
    },
    async exportCognitoGroupUsers() {
      this.cognitoGroupUsers.loading = true
      try {
        const cognitoGroupsResponse = await adminListGroups()
        const groups = cognitoGroupsResponse.Groups

        const promises = groups.map(async group => {
          const groupUsers = await adminListUsersInGroup(group.GroupName)
          return { name: group.GroupName, users: groupUsers }
        })
        await Promise.all(promises).then(values => {
          this.export('cognito-group-users', values);
        })
      }
      catch(e) { console.error(e) }
      this.cognitoGroupUsers.loading = false
    },
  }
}
</script>

<style scoped>

</style>
